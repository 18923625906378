footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background: var(--primary-color);
    padding: 1rem;
    h2 {
        font-family: 'Montserrat', sans-serif;
        font-variant: small-caps;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        font-size: 1.3rem;
    }
    a {
        text-decoration: none;
        color: var(--primary-text-color);
    }
    a:hover {
        text-decoration: none;
        color: var(--secondary-text-color)!important;
    }
    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        width: 40%;
        margin-top: 1rem;
        .articles-footer {
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }
        .acces-rapide-footer{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            .toggle-button[aria-expanded="true"] i{
                transform: rotate(90deg);
            }
            .child{
                margin-left: 1em;
            }
        }
        .social-media-footer {
            display: flex;
            gap: 1rem;
        }
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        row-gap: 2em;
        nav {
            width: 100%;
            text-align: center;
        }
    }
}