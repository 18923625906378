/** POST **/
.showpost-main {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    .showpost-bloc {
      width: 100% !important;
    }
    .article{
      display: flex;
      flex-direction: column;
    }
  }
  .article{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .showpost-bloc {
    width: 80%  ;
    p {
      text-align: justify;
    }
  }
  .tags {
    text-align: center;
    a {
      font-size: 0.8em;
      text-transform: uppercase;
      text-decoration: none;
      color: var(--primary-text-color);
      background-color: var(--secondary-text-color-light);
      padding: .5em;
      margin: 0.25em 0;
      display: inline-block;
    }
  }
  .article-content{
    * {
      border: 0;
    }
    p {
      margin-right:0;
      margin-left:0;
      text-align:justify;
      text-indent:15.0pt;
      line-height:15.0pt;
      font-size:11.0pt;
      font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
      color:#804050;
    }
  }
  h1,h2,h3,h4{
    color : #7D0505;
    &:hover{
      color: #99692A;
      border-radius: 15px;
    }
  }
  h5,h4{
    text-indent: 2em;
    margin-top: 0;
  }
  .ul_des_posts_actu,.ol_des_posts_actu{
    background-color: #CED0C3;
    box-shadow: 3px 3px 2px 1px #473421;
    border-radius: 15px;
    padding : 15px;
    margin : 15px;
  }
  .li_des_posts_actu{
    margin : 15px;
  }
  img{
    border-radius: 15px;
    box-shadow: 6px 6px 2px 1px #808080;
    margin: 20px;
  }
  .objet_fond_transparent{
    box-shadow: none;
  }
  #content #sommaire-post li a {
    color: #7D0505;
  }
}