/** Variable colors **/

:root {
    --main-color:#FBFBFB;
    --primary-color:#C7C9B9;
    --primary-text-color:#202020;
    --secondary-text-color:#99692A;
    --secondary-text-color-light:#B38C61;
}

.pagination{
    --bs-pagination-active-color: #99692A!important;
    --bs-pagination-active-border-color: #99692A!important;
    --bs-pagination-color: #99692A!important;
    --bs-link-hover-color-bg: #B38C61!important;
    --bs-link-hover-color: #202020!important;
}

body {
    position: relative;
    background: var(--main-color);
    width: 100vw;
    max-width: 100% !important;
    color: var(--primary-text-color);
    font-family: 'Montserrat', sans-serif !important;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main a {
    color: var(--secondary-text-color) !important;
}

/***** Header navbar *****/
.navbar {
    * {
        background-color: #E2E3D8;
    }

    .navbar-toggler {
        font-size: 0.8rem;
        z-index: 1;
    }
}

.hide {
    max-height: 0 !important;
    transition: all 0.2s ease-out;
    display: none;
}


/* Informations */
.list-unstyled, .list-unstyled a{
    color: #202020;
    text-decoration: none;
}

/** BREADCRUMB **/
.article-title {
    margin-top: 1em;
}
.breadcrumb-item a {
    text-decoration: none;
    font-size:.9em;
}


/** PAGINATION **/
.pagination {
    justify-content: center;
    .page-item.active .page-link {
        background-color: var(--bs-pagination-color);
        border-color: var(--bs-pagination-color);
    }
}

/** ASIDE **/
.navigation-menu-aside {
    width: 20%;
    margin: 2rem auto;
    .justify{
        text-align: justify;
    }
    .list-inline-item {
        font-size: .9em;
    }
    h3 {
        font-size: 1.4rem;
        color: #63664c;
    }
    @media screen and (max-width: 768px) {
        width: 80%;
        .hide-on-mobile{
            display: none;
        }
    }
}


/** PAGE **/
.container .content {
    min-height: 25rem;
}

/** CATEGORIES **/
.container{
    flex-grow: 2;
    margin: 2em;
}

*:hover{
    transition: all 0.5s ease-out;
}