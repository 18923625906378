@import './cavacs.scss';
@import './home.scss';
@import './header.scss';
@import './footer.scss';
@import './post.scss';

@import "~bootstrap/scss/bootstrap";

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/css/all.css';
