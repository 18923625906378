.homepage{
  padding: 2rem;
  width: 75%;
  margin: auto;
  section {
    a {
      text-decoration: none;
    }
    /** BLOCS D'APPERCU DES TEMOIGNAGES ET ACTUALITEES **/
    .apercu_posts {
      padding: 1.25rem 2.5rem;
      background: linear-gradient(180deg, rgb(199,201,185) 0%, rgba(199,201,185,0.46) 100%);
      border-radius: 0.625rem;
      height: fit-content;
      h3 {
        font-size: 2rem;
        font-weight: 700;
        font-variant: small-caps;
        margin-bottom: 1.5rem;
      }
      .post_content {
        text-align: justify;
        margin: 1rem 0;
        h4 {
          line-height: 1.5rem;
          font-size: 1.2rem;
          font-weight: 600;
        }
        a {
          font-weight: bold;
          color: #B38C61 !important;
          &:hover {
            color: #9e6c37 !important;
          }
        }
        p {
          font-style: italic;
        }
      }
      .post_auteur {
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
      }
      .posts_link {
        background-color: #9FA383;
        text-align: center;
        width: fit-content;
        padding: .5rem;
        margin-top: 1rem;
        border-radius: 1rem;
        &:hover {
          box-shadow: 2px 2px 4px #00000040;
          background-color: #989c7d;
        }
        a {
          text-shadow: 0 2px 2px #00000040;
          color: white !important;
          font-weight: 800;
          font-variant: small-caps;
          font-size: 1.2rem;
          &:hover {
            text-shadow: 0 3px 3px #00000040;
            cursor: pointer;
          }
        }
      }
    }
    .blocs-je-suis {
      @media screen and (max-width: 1000px) {
          flex-direction: column;
          gap: 2em;
      }
      button {
        border: none;
        background-color: transparent;
        font-variant: small-caps;
        font-weight: bold;
        font-size: 1.5rem;
        text-shadow: 0.05rem 0.05rem 0.05rem #00000040;
        color: white;
        width: 100%;
        &:hover {
          text-shadow: 0.2rem 0.2rem 0.2rem #00000040;
        }
        .fa-caret-down {
          color: rgba(255, 255, 255, 0.55);
          text-shadow: 0 0.25rem 0.25rem #00000040;
          margin-left: 2rem;
          @media screen and (min-width: 1000px) and (max-width: 1500px) {
            display: block;
            margin-left: 0;
          }
        }
      }
      > div {
        justify-content: center;
        background: linear-gradient(180deg, rgb(159,163,131) 0%, rgba(198,200,184,1) 95%);
        border-radius: 1.2rem;
        padding: 0.75rem;
        width: 20rem;
        margin: 0 4%;
        height: fit-content;
        @media screen and (max-width: 1000px) {
          margin: auto;
          width: 75%;
        }
        p {
          padding: 1rem;
          color: white;
          margin-bottom: 0!important;
          a {
            font-weight: 600;
            font-size: 1.2rem;
            font-variant: small-caps;
            color: #B38C61 !important;
            &:hover {
              color: #9e6c37 !important;
            }
          }
        }
      }
    }
    #rubriques {
      margin-top: 2rem;
      > div > div {
        margin: 2em;
        a {
          position: relative;
          img {
            width: 19rem;
            border-radius: 2.2rem !important;
          }
          div{
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            margin: 0;
            span{
              font-weight: bold;
              font-size: 1.5em;
              margin: 1.5rem;
              text-align: center;
            }
          }
        }
      }
    }
    #adhesion-newsletter iframe{
      width: 100%;
      border-radius: 2%;
      border: 1px solid var(--primary-text-color)
    }
  }
}

@media screen and (max-width: 1000px) {
  .homepage{
    margin:0;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .homepage{
    .d-flex.justify-content-around {
      flex-direction: column;
    }
    .d-flex.align-items-top {
      align-items: center;
    }
    .w-50{
      width: 100%!important;
    }
  }
}