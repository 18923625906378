header {
  #logo-link {
    width: fit-content;
    margin: 0 auto;
  }
  #textlogo {
    font-size: 20px;
    color: #99692A;
    margin: 1rem;
  }
  > nav {
    background-color: #E2E3D8;
    height: 2.8rem;
    .btn-adhesion-don {
      align-self: center;
      align-items: center;
      background-color: rgba(158, 162, 130, 1);
      border: 0.063rem none;
      border-radius: 0.5rem;
      box-shadow: 0.18rem 0.18rem 0.313rem #00000040;
      display: flex;
      gap: 0.625rem;
      height: 1.875rem;
      justify-content: center;
      padding: 0.625rem;
      width: fit-content;
      font-style: normal;
      text-decoration: none;
      color: white;
      font-weight: bold;
      margin-top: 0.2rem;
    }
    .btn-adhesion-don:hover {
      background-color: rgba(158, 162, 130, 0.8);
      box-shadow: 0.18rem 0.18rem 0.313rem #00000040;
      cursor: pointer;
      color: white;
      text-decoration: none !important;
    }
    .navbar-toggler{
      margin-right: 1em;
    }
    .nav-item{
      color:#626262;
      text-decoration: none;
      a {
        text-decoration: none;
      }
      &.dropdown:hover .dropdown-content {
        display: block;
      }
      .dropbtn {
        cursor: pointer;
        height: 100%;
        &:hover {
          color: var(--primary-text-color);
        }
      }
      .dropdown-content {
        display: none;
        position: absolute;
        top: 2.5rem;
        left: 0;
        box-shadow: 0 0 15px 2px var(--primary-color);
        width: 15rem;
        z-index: 1;
        a {
          color: var(--primary-text-color);
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          text-align: left;
          &:hover {
            background-color: var(--primary-color);
            color: var(--primary-text-color);
            text-shadow: 0 0.03rem 0.03rem rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}